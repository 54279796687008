import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

type SelectTagProps = {
    tags: DTO.BoardTag[]
    onChange: (tag: DTO.BoardTag | null) => void
    tagValue: DTO.BoardTag | null | undefined
}

const filter = createFilterOptions<DTO.BoardTag>()

const SelectTag = ({ tags, tagValue, onChange }: SelectTagProps): JSX.Element => (
    // ref: https://github.com/mui/material-ui/blob/v5.15.6/docs/data/material/components/autocomplete/FreeSoloCreateOption.tsx
    <Autocomplete
        value={tagValue}
        onChange={(event, value) => {
            if (typeof value === 'string') {
                onChange({ title: value })
            } else if (value?.inputValue) {
                // Create a new value from the user input
                onChange({ title: value.inputValue })
            } else {
                onChange(value)
            }
        }}
        filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const { inputValue } = params
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.title)
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                })
            }

            return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={tags}
        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
                return option
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
                return option.inputValue
            }
            // Regular option
            return option.title
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Add or create a board tag" />}
        size="small"
    />
)

export default SelectTag
