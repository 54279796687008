import type { FormikHelpers } from 'formik'
import React, { useState, useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next-nprogress-bar'
import {
    useCreateBoard,
    useBoardColumns,
    useQueryBoardTypes,
    useUpdateBoard,
    useWatchVariable,
} from '../../../lib/hooks'
import useToastContext from '../../../lib/hooks/useToastContext'
import handleError from '../../../lib/utils/handleError'
import { filterBoardColumns } from '../../../lib/utils/boards'
import BoardModalForm from './BoardModalForm'

type BoardModalProps = {
    open: boolean
    setOpen: (open: Partial<DTO.BoardModal> | null) => void
    level: Enum.BoardLevels
    boardId?: string
    initialVals?: Partial<DTO.BoardModal> | null
    redirect?: boolean
    tags?: DTO.BoardTag[]
}

const BoardModal = ({ open, setOpen, level, boardId, initialVals, redirect, tags }: BoardModalProps): JSX.Element => {
    const [boardEntityType, setBoardEntityType] = useState<Enum.BoardEntityType>(initialVals?.entityType || 'PLAYER')
    useWatchVariable(
        (entityType) => {
            if (entityType && entityType !== boardEntityType) setBoardEntityType(entityType)
        },
        initialVals?.entityType
    )

    const toastContext = useToastContext()
    const router = useRouter()
    const { data: session } = useSession()
    const { data: boardColumns } = useBoardColumns(boardEntityType, level)
    const { data: types } = useQueryBoardTypes()
    const { mutateAsync: create } = useCreateBoard()
    // @ts-expect-error this function will only get called when boardId is defined
    const { mutateAsync: update } = useUpdateBoard({ boardId, version: initialVals?.version })

    const filteredBoardColumns = filterBoardColumns(boardColumns, session, level)

    const onClose = useCallback(() => setOpen(null), [setOpen])
    const onSubmit = useCallback(
        (values: Partial<DTO.BoardModal>, { resetForm }: FormikHelpers<Partial<DTO.BoardModal>>) => {
            const submitValues = values as DTO.CreateUpdateBoard
            if (boardId)
                return update(submitValues, {
                    onSuccess: () => {
                        toastContext?.addToast({
                            severity: 'success',
                            message: 'Board Successfully Edited',
                        })
                        if (redirect) void router.push(`/boards/`)
                        resetForm()
                        onClose()
                    },
                    onError: (error) => handleError(error, toastContext),
                })

            return create(submitValues, {
                onSuccess: () => {
                    toastContext?.addToast({
                        severity: 'success',
                        message: 'Board Successfully Created',
                    })
                    if (redirect) void router.push(`/boards/${values.boardId as string}`)
                    resetForm()
                    onClose()
                },
                onError: (error) => handleError(error, toastContext),
            })
        },
        [boardId, create, redirect, router, onClose, toastContext, update]
    )

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                {boardId ? 'Save' : 'Create'} {level} Board
            </DialogTitle>
            {!!(open && session) && (
                <BoardModalForm
                    session={session}
                    level={level}
                    types={types}
                    boardEntityType={boardEntityType}
                    setBoardEntityType={setBoardEntityType}
                    boardColumns={filteredBoardColumns}
                    initialVals={initialVals}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    tags={tags}
                />
            )}
        </Dialog>
    )
}

export default BoardModal
