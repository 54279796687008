import type { Session } from 'next-auth'
import type { FormikHelpers } from 'formik'
import type { SelectChangeEvent } from '@mui/material/Select'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import SelectUser from '../../form/SelectUser'
import ErrorMessage from '../../form/ErrorMessage'
import { isSubmitButtonDisabled } from '../../form/formUtils'
import { useConstantsContext } from '../../../lib/contexts/ConstantsContext'
import SelectTag from './SelectTag'

const boardEntityTypes: { value: Enum.BoardEntityType; text: string }[] = [
    { value: 'PLAYER', text: 'Players' },
    { value: 'TEAM', text: 'Teams' },
]

const designationOptions: { value: Enum.BoardDesignation | 'NONE'; label: string }[] = [
    { value: 'NONE', label: 'None' },
    { value: 'AM_ASSIGNMENTS', label: 'Am. Assignments' },
    { value: 'PRO_ASSIGNMENTS', label: 'Pro Assignments' },
    { value: 'AM_MOCK_DRAFT', label: 'Am. Mock Draft' },
    { value: 'GLG_MOCK_DRAFT', label: 'G-League Mock Draft' },
    { value: 'PRO_TRADE_TIERS', label: 'Pro Trade Tiers' },
    { value: 'AM_PLAYER_RANKS', label: 'Am. Player Ranks' },
    { value: 'DRAFT_NIGHT', label: 'Draft Night' },
    { value: 'GLG_DRAFT_NIGHT', label: 'G-League Draft Night' },
    { value: 'AM_LEADERBOARD', label: 'Am. Leaderboard' },
]

type BoardModalFormProps = {
    session: Session
    level: Enum.BoardLevels
    onSubmit: (values: Partial<DTO.BoardModal>, formikeHelpers: FormikHelpers<Partial<DTO.BoardModal>>) => void
    types: DTO.BoardType[] | undefined
    boardColumns: DTO.BoardColumn[] | undefined
    boardEntityType: Enum.BoardEntityType
    initialVals?: Partial<DTO.BoardModal> | null
    onClose: () => void
    setBoardEntityType: (type: Enum.BoardEntityType) => void
    tags?: DTO.BoardTag[]
}

const validationSchema = yup.object().shape({
    headline: yup.string().required('Headline is required'),
    listColumns: yup.array().ensure().min(1),
    positionColumns: yup.array().ensure().min(1),
    entityType: yup.string().required('Entity Type is required'),
})
const BoardModalForm = ({
    session,
    level,
    onSubmit,
    types,
    boardColumns,
    boardEntityType,
    setBoardEntityType,
    initialVals,
    onClose,
    tags,
}: BoardModalFormProps): JSX.Element => {
    const isEditModal = !!initialVals?.boardId
    const [showAdvanced, setShowAdvanced] = useState(false)
    const { defaultPrimaryProBoardType, defaultPrimaryAmatuerBoardType, draftYear, salaryYear } = useConstantsContext()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [boardId] = useState(isEditModal ? initialVals.boardId! : uuidv4())
    const [initEditors] = useState(
        session.user?.name
            ? [
                  {
                      name: session.user.name,
                      abbr: session.user.name,
                      entityId: session.entityId,
                      id: Number(session.userId),
                  },
              ]
            : []
    )

    const canAddGraders = session.roles.featurePermissions['board-management']

    const formik = useFormik<Partial<DTO.BoardModal>>({
        validationSchema,
        onSubmit,
        initialValues:
            initialVals && Object.keys(initialVals).length
                ? initialVals
                : {
                      level,
                      boardId,
                      headline: '',
                      isRanked: true,
                      listColumns: [] as Enum.BoardColumns[],
                      positionColumns: [] as Enum.BoardColumns[],
                      audience: [] as DTO.BoardAudienceUser[],
                      type: (level === 'Amateur'
                          ? defaultPrimaryAmatuerBoardType
                          : defaultPrimaryProBoardType) as Enum.BoardTypes,
                      status: 'ACTIVE' as Enum.BoardStatus,
                      createdBy: Number(session.userId),
                      editors: initEditors,
                      designations: [],
                      entityType: boardEntityType,
                      tag: null,
                      graders: [] as DTO.BoardGrader[],
                      season: level === 'Amateur' ? draftYear : salaryYear,
                      allowDuplicateItems: false,
                      author: null,
                  },
    })

    const onEditorsChange = (e: SelectChangeEvent<unknown>): void => {
        const selectedEditors: DTO.BoardEditor[] = (e.target.value as DTO.User[]).map((x) => {
            const name = `${x.firstName} ${x.lastName}`
            return {
                name,
                abbr: name,
                entityId: x.entityId,
                id: x.id,
                boardId,
            }
        })
        if (!selectedEditors.some((x) => x.entityId === session.entityId)) {
            selectedEditors.push(...initEditors)
        }
        void formik.setFieldValue('editors', selectedEditors)
    }

    const onTagChange = (tag: DTO.BoardTag | null): void => {
        if (tag?.inputValue) {
            void formik.setFieldValue('tag', tag)
        } else if (tag?.title && !tag.inputValue) {
            void formik.setFieldValue('tag', { title: tag.title, inputValue: tag.title })
        } else {
            void formik.setFieldValue('tag', null)
        }
    }

    const onGradersChange = (e: SelectChangeEvent<unknown>): void => {
        const selectedGraders: DTO.BoardGrader[] = (e.target.value as DTO.User[]).map((x) => {
            const name = `${x.firstName} ${x.lastName}`
            return { id: x.id, name }
        })
        void formik.setFieldValue('graders', selectedGraders)
        if (selectedGraders.length > 0) {
            void formik.setFieldValue('isRanked', true)
            void formik.setFieldValue('allowDuplicateItems', false)
        }
    }

    const onAuthorChange = (e: SelectChangeEvent<unknown>): void => {
        const author = e.target.value as DTO.User
        const name = `${author.firstName} ${author.lastName}`
        void formik.setFieldValue('author', { id: author.id, name })
    }

    const handleDesignation = async (val: (Enum.BoardDesignation | 'NONE')[]) => {
        if (val.includes('NONE')) {
            await formik.setFieldValue('designations', [])
        } else {
            await formik.setFieldValue('designations', val)
        }
    }

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <DialogContent>
                <Grid sx={{ paddingTop: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            name="headline"
                            label="Board Name"
                            size="small"
                            placeholder="Enter Board Name"
                            fullWidth
                            value={formik.values.headline}
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                            error={formik.touched.headline && Boolean(formik.errors.headline)}
                            helperText={formik.touched.headline && formik.errors.headline}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectUser
                            name="editors"
                            label="Editors"
                            onChange={onEditorsChange}
                            value={formik.values.editors}
                        />
                    </Grid>

                    {!initialVals?.parentId && canAddGraders && (
                        <Grid item xs={12}>
                            <SelectUser
                                name="graders"
                                label="Graders"
                                onChange={onGradersChange}
                                value={formik.values.graders}
                            />
                        </Grid>
                    )}

                    <Grid xs={12} sm={12} md={12} lg={12} item>
                        <FormControl fullWidth size="small">
                            <InputLabel id="type-label">Board Type</InputLabel>
                            <Select
                                labelId="type-label"
                                label="Board Type"
                                name="type"
                                onChange={formik.handleChange}
                                value={formik.values.type}
                            >
                                {types
                                    ?.filter((d) => d.level === (level === 'G-League' ? 'Pro' : level))
                                    // Remove G-League Focus Board Type
                                    .filter((d) => d.type !== (level === 'G-League' ? 'G_LEAGUE' : ''))
                                    .filter((d) => d.entityType === formik.values.entityType || d.entityType === null)
                                    .map((u) => (
                                        <MenuItem value={u.type} key={u.type}>
                                            {u.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} item>
                        <FormControl fullWidth size="small">
                            <InputLabel id="tag-label" />
                            <SelectTag tags={tags || []} onChange={onTagChange} tagValue={formik.values.tag} />
                        </FormControl>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} item>
                        <FormControl fullWidth size="small">
                            <InputLabel id="type-label">Item Type</InputLabel>
                            <Select
                                label="Entity Type"
                                name="entityType"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setBoardEntityType(e.target.value as Enum.BoardEntityType)
                                    if (e.target.value !== formik.values.entityType) {
                                        void formik.setFieldValue('type', null)
                                    }
                                }}
                                value={formik.values.entityType}
                            >
                                {boardEntityTypes.map((u) => (
                                    <MenuItem value={u.value} key={u.value}>
                                        {u.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} lg={12} item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth size="small">
                                <DatePicker
                                    views={['year']}
                                    label="Season"
                                    openTo="year"
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    value={dayjs().year(formik.values.season!)}
                                    onChange={(date: dayjs.Dayjs | null) =>
                                        formik.setFieldValue('season', date?.get('year'))
                                    }
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                        },
                                    }}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>

                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={formik.handleChange}
                                        checked={!!formik.values.isRanked}
                                        name="isRanked"
                                        disabled={
                                            formik.isSubmitting ||
                                            (formik.values.graders && formik.values.graders.length > 0) ||
                                            !!initialVals?.parentId
                                        }
                                    />
                                }
                                label="Ranked"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12}>
                            <FormLabel id="columns-list">Columns &ndash; List View</FormLabel>
                        </Grid>
                        <FormGroup
                            sx={{ maxHeight: '275px', overflowY: 'scroll' }}
                            row
                            onChange={formik.handleChange}
                            color="primary"
                        >
                            {boardColumns?.map((b) => (
                                <Grid item xs={12} sm={6} key={b.title}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!formik.values.listColumns?.includes(b.type)}
                                                value={b.type}
                                                name="listColumns"
                                                disabled={formik.isSubmitting}
                                            />
                                        }
                                        label={<Typography>{b.title}</Typography>}
                                        key={b.type}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            ))}
                        </FormGroup>
                        {formik.touched.listColumns && Boolean(formik.errors.listColumns) && (
                            <ErrorMessage text="Select at least 1 column" />
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12}>
                            <FormLabel id="columns-position">Columns &ndash; Position View</FormLabel>
                        </Grid>
                        <FormGroup
                            sx={{ maxHeight: '275px', overflowY: 'scroll' }}
                            row
                            onChange={formik.handleChange}
                            color="primary"
                        >
                            {boardColumns?.map((b) => (
                                <Grid item xs={12} sm={6} key={b.title}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!formik.values.positionColumns?.includes(b.type)}
                                                value={b.type}
                                                name="positionColumns"
                                                disabled={formik.isSubmitting}
                                            />
                                        }
                                        label={<Typography>{b.title}</Typography>}
                                        key={b.type}
                                        labelPlacement="end"
                                    />
                                </Grid>
                            ))}
                        </FormGroup>
                        {formik.touched.positionColumns && Boolean(formik.errors.positionColumns) && (
                            <ErrorMessage text="Select at least 1 column" />
                        )}
                    </Grid>
                    <Grid item container xs={12} columnSpacing={2} alignItems="baseline">
                        <Grid item xs="auto">
                            <Typography variant="h6">Advanced</Typography>
                        </Grid>
                        <Grid item xs>
                            <Button
                                variant="text"
                                size="small"
                                onClick={() => setShowAdvanced(!showAdvanced)}
                                disabled={formik.isSubmitting}
                            >
                                {showAdvanced ? 'Hide' : 'Show'} Advanced
                            </Button>
                        </Grid>
                    </Grid>
                    {showAdvanced && (
                        <>
                            <Grid xs={12} item>
                                <SelectUser
                                    name="author"
                                    label="Author"
                                    onChange={onAuthorChange}
                                    value={formik.values.author}
                                    multiple={false}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <SelectUser
                                    name="audience"
                                    label="Audience"
                                    onChange={(e) => formik.setFieldValue('audience', e.target.value)}
                                    value={formik.values.audience}
                                    includeUserGroups
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="designation-label">Designation</InputLabel>
                                    <Select
                                        labelId="designation-label"
                                        label="Designation"
                                        name="designation"
                                        onChange={(e) =>
                                            handleDesignation(e.target.value as (Enum.BoardDesignation | 'NONE')[])
                                        }
                                        multiple
                                        value={formik.values.designations}
                                    >
                                        {designationOptions.map((d) => (
                                            <MenuItem
                                                value={d.value}
                                                key={d.value}
                                                sx={{ fontStyle: d.value === 'NONE' ? 'italic' : 'none' }}
                                            >
                                                {d.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={formik.handleChange}
                                            checked={formik.values.allowDuplicateItems}
                                            name="allowDuplicateItems"
                                            disabled={
                                                formik.isSubmitting ||
                                                (formik.values.graders && formik.values.graders.length > 0) ||
                                                !!initialVals?.parentId ||
                                                isEditModal
                                            }
                                        />
                                    }
                                    label={`Allow Duplicate ${
                                        formik.values.entityType === 'PLAYER' ? 'Players' : 'Teams'
                                    }`}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    onClick={() => {
                        onClose()
                        formik.resetForm()
                    }}
                    disabled={formik.isSubmitting}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={formik.isSubmitting}
                    disabled={isSubmitButtonDisabled({
                        isSubmitting: formik.isSubmitting,
                        dirty: formik.dirty,
                        isValid: formik.isValid,
                        submitCount: formik.submitCount,
                    })}
                >
                    {isEditModal ? 'Save' : 'Add'} Board
                </LoadingButton>
            </DialogActions>
        </form>
    )
}

export default BoardModalForm
