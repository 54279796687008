import type { SelectProps } from '@mui/material/Select'
import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useQueryUsers, useQueryUserGroups } from '../../lib/hooks'
import { usersQueryKey } from '../../lib/hooks/useUsers'
import { userGroupsQueryKey } from '../../lib/hooks/useUserGroups'

type UserOrUserGroupValue = Pick<DTO.BoardAudienceUser, 'type' | 'id'>
type UserValue = Pick<DTO.User, 'id'>
type SelectUserValueType =
    | UserOrUserGroupValue
    | UserOrUserGroupValue[]
    | UserValue
    | UserValue[]
    | undefined
    | null
    | ''
type SelectUserProps = {
    name: string
    label: string
    onChange: SelectProps['onChange']
    value: SelectUserValueType
    multiple?: boolean
    includeUserGroups?: boolean
    includeInactiveUsers?: boolean
}

const isUserOrUserGroup = (
    _val: UserOrUserGroupValue | UserValue,
    includeUserGroups: boolean
): _val is UserOrUserGroupValue => includeUserGroups
const getOptionId = (val: UserOrUserGroupValue | UserValue, includeUserGroups: boolean): string =>
    isUserOrUserGroup(val, includeUserGroups) ? `${val.type}-${val.id}` : val.id.toString()

const SelectUser = ({
    name,
    label,
    onChange,
    value,
    multiple = true,
    includeUserGroups = false,
    includeInactiveUsers = false,
}: SelectUserProps): JSX.Element => {
    const [options, setOptions] = useState<
        DTO.DepthChartAudience[] | DTO.BoardAudienceUser[] | DTO.User[] | undefined
    >()
    const { data: users } = useQueryUsers<DTO.User>({
        queryKey: usersQueryKey,
        params: { includeInactiveUsers },
    })
    const { data: userGroups } = useQueryUserGroups<DTO.UserGroupWithPermissions>({
        queryKey: userGroupsQueryKey,
        options: { enabled: includeUserGroups },
    })

    useEffect(() => {
        if (includeUserGroups) {
            const usersObj: DTO.BoardAudienceUser[] | undefined = users?.map((x) => ({
                name: `${x.firstName} ${x.lastName}`,
                type: 'USER',
                id: x.id,
            }))
            const userGroupsObj: DTO.BoardAudienceUser[] | undefined = userGroups?.map((x) => ({
                name: `${x.displayName}`,
                type: 'USER_GROUP',
                id: x.id,
            }))
            setOptions(usersObj?.concat(userGroupsObj || []))
        } else {
            setOptions(users)
        }
    }, [users, userGroups, includeUserGroups])

    let selectValue: SelectUserValueType = ''
    if (value && options) {
        const selected = Array.isArray(value) ? value : [value]
        const selectedKeys = new Set(selected.map((x) => getOptionId(x, includeUserGroups)))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const filteredOptions = (options as (DTO.DepthChartAudience | DTO.BoardAudienceUser | DTO.User)[]).filter((o) =>
            selectedKeys.has(getOptionId(o, includeUserGroups))
        ) as DTO.DepthChartAudience[] | DTO.BoardAudienceUser[] | DTO.User[]
        selectValue = multiple ? filteredOptions : filteredOptions[0]
    }
    if (multiple && !selectValue) selectValue = []

    const labelId = `${name}-label`
    return (
        <FormControl fullWidth size="small">
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                label={label}
                name={name}
                multiple={multiple}
                onChange={onChange}
                value={selectValue}
                MenuProps={{ sx: { maxHeight: 400 } }}
            >
                {options?.map((u, i) => (
                    // @ts-expect-error raw object can be used for value
                    <MenuItem
                        key={getOptionId(u, includeUserGroups)}
                        value={u}
                        sx={{ borderTop: i === users?.length ? 1 : undefined }}
                    >
                        {isUserOrUserGroup(u, includeUserGroups) ? u.name : `${u.firstName} ${u.lastName}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectUser
