import type { ExpandableContentProps } from '../Table/TableBody'
import React, { useCallback, useState, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { useSession } from 'next-auth/react'
import Stack from '@mui/material/Stack'
import Table from '../Table'
import LoadingSkeleton from '../Table/LoadingSkeleton'
import tableConfig from './BoardListTableConfig'
import BoardModal from './BoardModal'

type BoardListProps = {
    level: Enum.BoardLevels
    showArchived: boolean
    boardList: DTO.BoardModal[] | undefined
    isLoading: boolean
}

export const getBoardTags = (boardList: DTO.BoardModal[] | undefined): DTO.BoardTag[] => {
    const uniqueTagSet = new Set<string>()
    const uniqueTagList: DTO.BoardTag[] = []
    boardList?.forEach((b) => {
        if (b.tag && !uniqueTagSet.has(b.tag.title)) {
            uniqueTagSet.add(b.tag.title)
            uniqueTagList.push(b.tag)
        }
    })
    return uniqueTagList
}

const BoardList = ({ level, showArchived, boardList, isLoading }: BoardListProps): JSX.Element => {
    const { data: session } = useSession()
    const [openModal, setOpenModal] = useState<Partial<DTO.BoardModal> | null>(null)
    const [showEditAction, setShowEditAction] = useState('')

    const [boardsFiltered, childBoards] = useMemo(() => {
        const childrenMap: Record<string, DTO.BoardModal[]> = {}
        let boards: DTO.BoardModal[] = []

        const filteredBoardIds = new Set<string>()
        boardList?.forEach((b) => {
            if (b.level === level && (showArchived || b.status !== 'ARCHIVED')) {
                if (b.parentId) {
                    const children = b.parentId in childrenMap ? childrenMap[b.parentId] : []
                    childrenMap[b.parentId] = [...children, b]
                } else {
                    boards.push(b)
                    filteredBoardIds.add(b.boardId)
                }
            }
        })

        Object.keys(childrenMap).forEach((k) => {
            if (!filteredBoardIds.has(k)) {
                boards = [...boards, ...childrenMap[k]]
            }
        })

        return [boards, childrenMap]
    }, [level, showArchived, boardList])

    const ChildBoardsTable = useCallback(
        ({ row: board }: ExpandableContentProps<DTO.BoardModal>) => (
            <Stack paddingY={1} spacing={1}>
                <Typography variant="body1" paddingX={1} fontWeight={500}>
                    Graders
                </Typography>
                <Table<DTO.BoardModal, unknown>
                    rows={childBoards[board.boardId]}
                    showEditAction={showEditAction}
                    setShowEditAction={setShowEditAction}
                    config={tableConfig(setOpenModal, session, true)}
                    getRowKey={(r) => r.boardId}
                    onMouseEnter={(b) => setShowEditAction(b.boardId)}
                    onMouseLeave={() => setShowEditAction('')}
                    formatRow={(b) =>
                        b.status === 'ARCHIVED'
                            ? { '.MuiTableCell-root': { color: 'darkgrey' } }
                            : {
                                  '.MuiTableCell-root': {
                                      height: '38px',
                                      paddingY: '0px',
                                  },
                              }
                    }
                    hover={false}
                />
            </Stack>
        ),
        [childBoards, session, showEditAction]
    )

    return (
        <Grid item>
            <Paper variant="outlined">
                <Box display="flex" justifyContent="space-between">
                    <Typography marginY={1} marginLeft={2} variant="h6">
                        {level} Boards
                    </Typography>
                </Box>
                {isLoading ? (
                    <LoadingSkeleton config={tableConfig(setOpenModal, session)} />
                ) : (
                    <>
                        <Table<DTO.BoardModal, unknown>
                            config={tableConfig(setOpenModal, session)}
                            rows={boardsFiltered}
                            showEditAction={showEditAction}
                            setShowEditAction={setShowEditAction}
                            getRowKey={(b) => b.boardId}
                            onMouseEnter={(b) => setShowEditAction(b.boardId)}
                            onMouseLeave={() => setShowEditAction('')}
                            formatRow={(b) =>
                                b.status === 'ARCHIVED'
                                    ? { '.MuiTableCell-root': { color: 'darkgrey' } }
                                    : {
                                          '.MuiTableCell-root': {
                                              height: '38px',
                                              paddingY: '0px',
                                          },
                                      }
                            }
                            hover={false}
                            expandableContent={
                                boardsFiltered.some((d) => d.graders?.length) ? ChildBoardsTable : undefined
                            }
                            isRowExpandable={(r) => !!r.graders?.length}
                        />
                        {session?.roles.featurePermissions['custom-boards'] && (
                            <Button
                                sx={{ marginTop: 3, marginBottom: 2, marginX: 2 }}
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => setOpenModal({})}
                            >
                                {level} Board
                            </Button>
                        )}
                        <BoardModal
                            level={level}
                            open={!!openModal}
                            setOpen={setOpenModal}
                            boardId={openModal?.boardId}
                            initialVals={openModal}
                            redirect
                            tags={getBoardTags(boardList)}
                        />
                    </>
                )}
            </Paper>
        </Grid>
    )
}

export default BoardList
