'use client'

import type { HydrateProps } from '@tanstack/react-query'
import React from 'react'
import { Hydrate as RQHydrate } from '@tanstack/react-query'

function Hydrate(props: HydrateProps): JSX.Element {
    return <RQHydrate {...props} />
}

export default Hydrate
