import type { SystemCssProperties } from '@mui/system'
import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box } from '@mui/system'
import { useRouter } from 'next-nprogress-bar'
import ConfirmationDialog from '../dialog/ConfirmationDialog'
import { useUpdateBoardStatus } from '../../lib/hooks'
import Controls from '../Controls'
import { useCopyBoard } from '../../lib/hooks/useBoards'

type EditBoardOptionProps = {
    board: DTO.CreateUpdateBoard
    setModal: (board: DTO.CreateUpdateBoard) => void
    style?: SystemCssProperties | undefined
}

const EditBoardOptions = ({ board: b, setModal, style }: EditBoardOptionProps): JSX.Element => {
    const { mutateAsync } = useUpdateBoardStatus()
    const { mutateAsync: copy } = useCopyBoard()
    const router = useRouter()

    const handleUpdateStatus = async (status: Enum.BoardStatus, boardId: string): Promise<void> => {
        await mutateAsync({ boardId, status, version: b.version })
        void router.push(`/boards/`)
    }

    const handleCopy = async (boardId: string): Promise<void> => {
        await copy({ boardId })
        void router.push(`/boards/`)
    }

    return (
        <Box sx={style}>
            <Controls>
                {b.status !== 'ARCHIVED' ? (
                    <MenuItem onClick={() => setModal(b)} sx={{ gap: 1 }}>
                        <EditIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                        Edit
                    </MenuItem>
                ) : (
                    <>{}</>
                )}
                <ConfirmationDialog
                    trigger={
                        <MenuItem sx={{ gap: 1 }}>
                            <MoveToInboxIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                            {b.status === 'ARCHIVED' ? 'Unarchive' : 'Archive'}
                        </MenuItem>
                    }
                    headerText={`${b.status === 'ARCHIVED' ? 'Unarchive' : 'Archive'} ${b.headline ? b.headline : ''}?`}
                    confirmText={b.status === 'ARCHIVED' ? 'Unarchive' : 'Archive'}
                    handleConfirm={() =>
                        handleUpdateStatus(
                            `${b.status === 'ARCHIVED' ? 'ACTIVE' : 'ARCHIVED'}`,
                            b.boardId ? b.boardId : ''
                        )
                    }
                    deletion={b.status === 'ACTIVE'}
                />
                <ConfirmationDialog
                    trigger={
                        <MenuItem sx={{ gap: 1 }}>
                            <DeleteIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                            Delete
                        </MenuItem>
                    }
                    headerText={`Delete ${b.headline ? b.headline : ''}?`}
                    confirmText="Delete"
                    handleConfirm={() => handleUpdateStatus('DELETED', b.boardId ? b.boardId : '')}
                />
                <ConfirmationDialog
                    trigger={
                        <MenuItem sx={{ gap: 1 }}>
                            <ContentCopyIcon style={{ color: 'grey', fontSize: 'inherit' }} />
                            Copy
                        </MenuItem>
                    }
                    headerText={`Copy ${b.headline ? b.headline : ''}?`}
                    confirmText="Copy"
                    handleConfirm={() => handleCopy(b.boardId)}
                    deletion={false}
                />
            </Controls>
        </Box>
    )
}

export default EditBoardOptions
